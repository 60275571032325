import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './UploadDropZone.module.css';

interface UploadDropZoneProps {
  onChange?: (acceptedFiles: File[]) => void;
  disabled?: boolean;
  children: React.ReactNode; // add this line to allow children to be passed
}

const UploadDropZone: React.FC<UploadDropZoneProps> = ({ onChange, disabled, children }) => {
  const [fileErrorMessage, setFileErrorMessage] = useState<string>("");
  const [fileName, setFileName] = useState<string | null>(null);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setFileName(acceptedFiles[0].name);
    }

    if (onChange) {
      onChange(acceptedFiles);
    }
  }, [onChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    disabled ? <div {...getRootProps()} className={`${styles.dropzone} ${isDragActive ? styles['is-drag-active'] : ''} ${disabled ? styles.dropzone_disabled : null}`}>
    <span>Delete voice comms to re-upload.</span>
  </div> :
  <div {...getRootProps()} className={`${styles.dropzone} ${isDragActive ? styles['is-drag-active'] : ''} ${disabled ? styles.dropzone_disabled : null}`}>
      <input {...getInputProps()} disabled={disabled}/>
      {
        fileName ?
        <p>Selected: <code>{fileName}</code></p> :
        children
      }
      {fileErrorMessage && <div className={styles.error}>{fileErrorMessage}</div>}
    </div>
  );
};

export default UploadDropZone;


