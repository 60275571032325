import React from "react";
import styles from "./VolumeMeter.module.css";

interface VolumeMeterProps {
  amplitude: number;
}

const VolumeMeter: React.FC<VolumeMeterProps> = ({ amplitude }) => {
  // Define the maximum height of the bar (this will be the height in one direction)
  const maxHeight = 90; // Height in pixels for the upward or downward direction
  const minHeight = 1; // Minimum height of the bar to show even when amplitude is very low

  // Non-linear transformation function to boost quieter signals
  const applyNonLinearGain = (amplitude: number) => {
    const factor = 1.9; // Controls the steepness of the curve
    return Math.pow(amplitude, 1 / factor);
  };

  // Calculate the height of the bar based on the amplitude
  const adjustedHeight = Math.max(minHeight, applyNonLinearGain(amplitude) * maxHeight);

  // The total height is double the adjusted height because it grows in both directions
  const totalHeight = adjustedHeight * 2;
  
  return (
    <div className={styles.volumeMeterContainer}>
      <div
        className={styles.volumeMeterBar}
        style={{ height: `${totalHeight}%` }}
      />
    </div>
  );
};

export default VolumeMeter;
