import React, { useState } from 'react';
import { Button, Form, Container, Row, Col, ProgressBar, Spinner } from 'react-bootstrap';
import styles from './PlaylistImport.module.css';
import { fetchFromApi } from '../utils/api';

interface PlaylistImportProps { }

const PlaylistImport: React.FC<PlaylistImportProps> = () => {
  const [csvData, setCsvData] = useState<string>('2024-09-12;vitality;furia;dust2;1\n2024-09-12;vitality;furia;dust2;3');
  const [playlistName, setPlaylistName] = useState<string>('');
  const [playlist, setPlaylist] = useState<string>('');
  const [totalRounds, setTotalRounds] = useState<number>(0);
  const [emptyRounds, setEmptyRounds] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [parsing, setParsing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false); // State for saving spinner

  // String cleanup function
  const cleanString = (str: string): string => {
    return str
      .replace(/[^a-zA-Z0-9]/g, '-')  // Replace non-alphanumeric with '-'
      .replace(/-+/g, '-')            // Remove consecutive '-'
      .toLowerCase();                 // Make lowercase
  };

  // Function to call API (GET /round_id)
  const getRoundId = async (date: string, teamA: string, teamB: string, mapname: string, roundnum: string): Promise<string> => {
    const query = new URLSearchParams({
      date,
      teamA_key: teamA,
      teamB_key: teamB,
      mapname,
      roundnum,
    });

    try {
      const response = await fetchFromApi(`/round_id?${query}`);
      const data = await response.json();
      return data.round_id || '';
    } catch (error) {
      console.error('API call failed:', error);
      return '';
    }
  };

  const handleValidate = async (): Promise<void> => {
    setEmptyRounds(0);
    setTotalRounds(0);
    setProgress(0);
    setParsing(true);
    setPlaylist(''); // Clear the playlist
    const rows = csvData.split('\n');
    let validRounds: string[] = [];
    let emptyRoundsCount = 0;

    for (let i = 0; i < rows.length; i++) {
      const columns = rows[i].split(';');
      if (columns.length === 5) {
        const [date, teamA, teamB, mapname, roundnum] = columns;
        const cleanedTeamA = cleanString(teamA);
        const cleanedTeamB = cleanString(teamB);
        const cleanedMapname = cleanString(mapname);

        const roundId = await getRoundId(date, cleanedTeamA, cleanedTeamB, cleanedMapname, roundnum);
        if (roundId) {
          validRounds.push(roundId);
          setPlaylist((prev) => prev + roundId + '\n'); // Update playlist as we go
        } else {
          emptyRoundsCount++;
        }

        // Update progress
        setProgress(Math.round(((i + 1) / rows.length) * 100));
        setTotalRounds(i + 1);
        setEmptyRounds(emptyRoundsCount);
      }
    }

    setParsing(false);
  };

  const handleSavePlaylist = async (): Promise<void> => {
    if (playlistName === '' || playlist === '') {
      alert('Please enter a valid playlist name and valid playlist before saving.');
      return;
    }

    setSaving(true); // Start saving spinner

    const newPlaylist = {
      playlist_id: undefined,
      name: playlistName,
      items: playlist.split('\n').filter((round) => round.length > 0)
    };

    fetchFromApi('/playlist', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newPlaylist)
    }).then(() => {
      setSaving(false); // Stop saving spinner
      // Redirect to the playlist page
      window.location.href = '/playlists';
    }).catch(() => {
      setSaving(false); // Stop saving spinner on error
    });
  }

  return (
    <div className='container match-table-container' style={{ marginTop: '100px' }}>
      <Container className={styles.playlistImport}>
        <Row className="mb-4">
          <Col>
            <Form.Group controlId="formCsvData">
              <Form.Label>CSV Data (<span className={styles.csvFormat}>date;teamA;teamB;map;round</span>)</Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                value={csvData}
                onChange={(e) => setCsvData(e.target.value)}
                placeholder="2024-09-12;vitality;furia;dust2;1"
                className={styles.textarea}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-4 align-items-center">
          <Col xs="auto">
            <Button onClick={handleValidate} className="mr-2">
              Parse
            </Button>
          </Col>
          <Col>
            {parsing && (
              <p className="d-flex align-items-center mb-0">
                <Spinner className="spinner-border-sm" animation="border" style={{ color: "#888", marginRight: '10px' }} />
                Items parsed ({totalRounds}/{csvData.split('\n').length}) with {emptyRounds} errors
              </p>
            )}
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <Form.Group controlId="formPlaylist">
              <Form.Label>Playlist</Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                value={playlist}
                readOnly
                placeholder="Validated rounds for the playlist"
                className={styles.textarea}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <Form.Group controlId="formPlaylistName">
              <Form.Label>Playlist Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter playlist name"
                value={playlistName}
                onChange={(e) => setPlaylistName(e.target.value)}
                className={styles.textInput}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-4 align-items-center">
          <Col xs="auto">
            <Button onClick={handleSavePlaylist} variant="primary" disabled={saving}>
              Create Playlist
            </Button>
          </Col>
          <Col>
            {saving && (
              <p className="d-flex align-items-center mb-0">
                <Spinner className="spinner-border-sm" animation="border" style={{ color: "#888", marginRight: '10px' }} />
                Saving...
              </p>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PlaylistImport;
