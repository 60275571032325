import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import styles from './AlertPopup.module.css';

interface AlertPopupProps {
  show: boolean;
  delay?: number | undefined;
  backgroundColor?: string | undefined;
  borderColor?: string | undefined;
  onClose: () => void;
  children: React.ReactNode;
}

const AlertPopup: React.FC<AlertPopupProps> = ({ show, onClose, children, delay, backgroundColor, borderColor }) => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (show) {
      setFadeOut(false);
      const timer = setTimeout(() => {
        setFadeOut(true);
        setTimeout(onClose, 300);
      }, delay || 2200);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [show, delay, onClose]);

  return (
    <div
      style={{
        position: 'absolute',
        top: '35px',
        left: 0,
        right: 0,
        maxWidth: '38vw',
        margin: '0 auto',
        zIndex: 1000,
      }}
      className={fadeOut ? styles.fadeOut : ''}
    >
      {show && (
        <Alert variant="secondary" onClose={onClose} dismissible className={`${styles.alertPopup} ${styles.centerContent}`} style={{backgroundColor: backgroundColor, borderColor: borderColor}}>
          {children}
        </Alert>
      )}
    </div>
  );
};

export default AlertPopup;
