import React from "react";
import styles from './VoiceCommWaveform.module.css';

interface VoiceCommWaveformProps {
  waveform: number[];
  length: number; // Total duration of the audio in seconds
  currentTime: number; // Current time in seconds for the vertical line
  voiceStart: number; // Start time of the voice segment in seconds
  scale: number; // Number of seconds to render in total
  onClickWaveform: (time: number) => void; // Callback to handle click event, time in seconds
}

const VoiceCommWaveform: React.FC<VoiceCommWaveformProps> = ({ waveform, length, currentTime, scale, voiceStart, onClickWaveform }) => {
  const height = 10; // SVG height
  const width = 70; // SVG width

  // Non-linear transformation function to boost quieter signals
  const applyNonLinearGain = (amplitude: number) => {
    const factor = 1.9; // Controls the steepness of the curve
    return Math.pow(amplitude, 1 / factor) + 0.01;
  };

  const generatePath = (rangeStartIndex: number, rangeEndIndex: number, segmentStartIndex: number, segmentEndIndex: number) => {
    let path = '';
    const centerY = height / 2;

    // Slice the waveform to get the segment we want to render
    const waveformSegment = waveform.slice(rangeStartIndex, rangeEndIndex);
    const step = width / (waveformSegment.length - 1); // Adjust to account for the full range
    const segmentStartOffset = segmentStartIndex - rangeStartIndex;
    const segmentEndOffset = segmentEndIndex - rangeStartIndex;
    waveformSegment.forEach((amplitude, index) => {
      if(index < segmentStartOffset || index > segmentEndOffset) {
          return;
      }
      const transformedAmplitude = applyNonLinearGain(amplitude);
      const x = index * step;
      const y = centerY - transformedAmplitude * height * 0.5;
      path += `${path.length === 0 ? 'M' : 'L'}${x},${y} `;;
    });

    waveformSegment.reverse().forEach((amplitude, index) => {
      const reversedIndex = waveformSegment.length - 1 - index;
      if(reversedIndex < segmentStartOffset || reversedIndex > segmentEndOffset) {
        return;
      }
      const transformedAmplitude = applyNonLinearGain(amplitude);
      const x = reversedIndex * step;
      const y = centerY + transformedAmplitude * height * 0.5;
      path += `L${x},${y} `;
    });
    path += 'Z'; // Close the path
    return path;
  };

  const renderWaveform = (afterVoice: boolean, voiceTime: number) => {
    // Calculate the start and end time based on currentTime and scale
    const halfScale = scale / 2;

    // Adjust the start and end time logic to handle the beginning and end cases
    let startTime = currentTime - halfScale;
    let endTime = currentTime + halfScale;

    if (currentTime < halfScale) {
      startTime = 0;
      endTime = Math.min(scale, length);
    }

    if (currentTime > length - halfScale) {
      startTime = Math.max(length - scale, 0);
      endTime = length;
    }

    const startIndex = Math.floor((startTime / length) * waveform.length);
    const endIndex = Math.ceil((endTime / length) * waveform.length);

    if(afterVoice) {
        if(endTime < voiceTime) {
            return '';
        }
        startTime = Math.max(voiceTime, startTime);
    } else {
        if(startTime > voiceTime) {
            return '';
        }
        endTime = Math.min(voiceTime, endTime);
    }

    // Calculate the corresponding indices in the waveform array
    const segmentStartIndex = Math.floor((startTime / length) * waveform.length);
    const segmentEndIndex = Math.ceil((endTime / length) * waveform.length);

    const path = generatePath(startIndex, endIndex, segmentStartIndex, segmentEndIndex);
    return path;
  };

  const renderCurrentTimeLine = () => {
    const halfScale = scale / 2;
    let startTime = currentTime - halfScale;
    let endTime = currentTime + halfScale;

    if (currentTime < halfScale) {
      startTime = 0;
      endTime = Math.min(scale, length);
    }

    if (currentTime > length - halfScale) {
      startTime = Math.max(length - scale, 0);
      endTime = length;
    }

    // Calculate the position of the currentTime line within the visible segment
    const timeInRange = (currentTime - startTime) / (endTime - startTime);
    const x = timeInRange * width;

    return <line x1={x} x2={x} y1="0" y2={height} stroke="#B0828D" strokeWidth="0.25" />;
  };

  const handleClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const clickX = event.clientX - rect.left; // X position relative to the SVG
    
    // Calculate the start and end time based on currentTime and scale
    const halfScale = scale / 2;
    let startTime = currentTime - halfScale;
    let endTime = currentTime + halfScale;

    if (currentTime < halfScale) {
      startTime = 0;
      endTime = Math.min(scale, length);
    }

    if (currentTime > length - halfScale) {
      startTime = Math.max(length - scale, 0);
      endTime = length;
    }

    // Translate the click position (clickX) to a time in seconds
    const timeInRange = (clickX / rect.width) * (endTime - startTime) + startTime;
    onClickWaveform(timeInRange);
  };

  return (
    <svg
      className={styles.waveformContainer}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="none"
      onClick={handleClick} // Add the click handler here
    >
      <defs>
        <linearGradient id="waveGradientVoiceCommBefore" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style={{ stopColor: '#666688', stopOpacity: 0.5 }} />
          <stop offset="100%" style={{ stopColor: '#666688', stopOpacity: 0.3 }} />
        </linearGradient>
        <linearGradient id="waveGradientVoiceCommAfter" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style={{ stopColor: '#90528D', stopOpacity: 1.0 }} />
          <stop offset="100%" style={{ stopColor: '#90528D', stopOpacity: 0.75 }} />
        </linearGradient>
      </defs>
      <path
        d={renderWaveform(false, voiceStart)}
        fill="url(#waveGradientVoiceCommBefore)"
      />
      <path
        d={renderWaveform(true, voiceStart)}
        fill="url(#waveGradientVoiceCommAfter)"
      />
      {renderCurrentTimeLine()}
    </svg>
  );
};

export default VoiceCommWaveform;
