import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button, Modal, Form, Col, Row, OverlayTrigger, Tooltip, Overlay, ToggleButtonGroup, ToggleButton, Spinner, ProgressBar, InputGroup, FormControl } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import CustomModalHeader from './CustomModalHeader';
import UploadDropZone from './UploadDropZone';
import footerStyles from './FooterButton.module.css';
import styles from './VoiceCommsButton.module.css';
import { fetchFromApi } from '../utils/api';
import VoiceCommWaveform from './VoiceCommWaveform';
import VolumeMeter from './VolumeMeter';
import { getHasSubscription } from '../utils/premium';
import UpgradeInfoModal from './UpgradeInfoModal';
import { propTypes } from 'react-bootstrap/esm/Image';


interface VoiceCommsButtonProps {
  matchId: string;
  mapName: string;
  loading: boolean;
  loadingProgress: number;
  voiceTime: number; // The current voice time offset in seconds
  waveform: number[] | undefined; // The waveform data for the voice comms
  voiceLength: number,
  voiceBuffer: ArrayBuffer | null;
  premium: boolean,
  onClick: () => void;
  onClose: () => void; // The callback function for the close event
  onUploadDone: () => void; // The callback function for the upload done event
  onSetVoiceTime: (value: number) => void; // The callback function for setting voice time offset
  onDelete: () => void; // The callback function for deleting voice comms
}

export default function VoiceCommsButton({ onClose, onUploadDone, onSetVoiceTime, onClick, onDelete, matchId, mapName, loading, loadingProgress, voiceTime, waveform, voiceLength, voiceBuffer, premium }: VoiceCommsButtonProps): JSX.Element {
  const [showModal, setShowModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState<number | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string>(loading ? "LOADING" : (waveform !== undefined ? "SYNC" : "NEW"));
  const [progress, setProgress] = useState<number>(0);
  const [fileErrorMessage, setFileErrorMessage] = useState<string | null>(null);
  const [statusErrorMessage, setStatusErrorMessage] = useState<string>("");
  const [offsetSaving, setOffsetSaving] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [playing, setPlaying] = useState<boolean>(false); // New state for playing status
  const [orgName, setOrgName] = useState<string | undefined>(undefined);

  const target = useRef(null);
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const abortControllerRef = useRef<AbortController | null>(null);

  const handleShowModal = (): void => {
    setShowModal(true);
    if (!loading) {
      setStatusErrorMessage("");
      setFileErrorMessage(null);
      setProgress(0);
      setDeleting(false);
      setUploadStatus(waveform !== undefined ? "SYNC" : "NEW");
    }
  }
  const handleCloseModal = (): void => {
    /* Release audio context */
    if (audio) {
      audio.pause();
      audio.src = '';
      audio.load();
      setAudio(null);
    }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();  // Cancel the upload
      setUploadStatus("NEW");
      setProgress(0)
    }

    if (uploadStatus === "DONE") {
      setUploadStatus("SYNC");
    }
    setShowModal(false);
    onClose();
  }

  const handleKeyDown = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
  };

  const buttonClasses = showModal
    ? `${footerStyles.button} ${footerStyles.buttonActivated}`
    : footerStyles.button;

  const handleMouseEnter = () => {
    const timeout = window.setTimeout(() => {
      setShowTooltip(true);
    }, 300) as unknown as number; // cast to number
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout) {
      window.clearTimeout(hoverTimeout);
    }
    setShowTooltip(false);
    setHoverTimeout(null);
  };

  const handleFileChange = (files: File[]) => {
    if (files.length === 0) {
      setFileErrorMessage("No file selected");
      return;
    }
    if (files[0].name.split('.').pop() !== 'wav') {
      setFileErrorMessage("Invalid file type. Please upload a .wav file.");
      return;
    }
    uploadWav(files[0]);
  }

  const stateToProgressBarStyle = (uploadStatus: string) => {
    if (uploadStatus === "UPLOADING") {
      return "uploadProgressBar";
    } else if (uploadStatus === "PROCESSING") {
      return "processingProgressBar";
    } else if (uploadStatus === "LOADING") {
      return "loadingProgressBar";
    }
    return "";
  }

  const uploadWav = (file: File) => {
    const PART_SIZE = 10 * 1024 * 1024; // 10MB per part
    const MAX_PARALLEL_UPLOADS = 3; // Limit to 3 parallel uploads

    setUploadStatus("INITIALIZING");

    // Initialize AbortController
    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    const data = {
      ext: 'wav',
      match_id: matchId,
      map_key: mapName,
      part_count: Math.ceil(file.size / PART_SIZE) // Divide file into chunks
    };

    fetchFromApi(`/upload_voice_multipart`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
      signal,  // Pass the signal to fetch
    })
      .then((response) => response.json())
      .then(async (data) => {
        const { urls, multipart_upload_id } = data;

        // Array to store individual part progress
        const partProgress = new Array(urls.length).fill(0);

        interface UploadPartResult {
          ETag: string;
          PartNumber: number;
        }
        const uploadParts: UploadPartResult[] = [];

        // Function to upload a single part
        const uploadPart = async (url: string, chunk: Blob, i: number): Promise<UploadPartResult> => {
          const urlWithContentType = `${url}&content-type=${data.content_type}`;

          return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', urlWithContentType);

            // Attach abort signal to XMLHttpRequest
            xhr.addEventListener('abort', () => {
              console.log('Upload canceled');
              reject(new Error(`Upload part ${i + 1} aborted`));
            });

            xhr.upload.addEventListener('progress', (e) => {
              if (e.lengthComputable) {
                // Calculate progress for this part
                const percentage = Math.round((e.loaded / e.total) * 100);
                partProgress[i] = percentage;
                // Calculate overall progress
                const totalProgress = Math.round(partProgress.reduce((acc, curr) => acc + curr, 0) / urls.length);
                setUploadStatus("UPLOADING");
                setProgress(totalProgress);
              }
            });

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  const etag = xhr.getResponseHeader('ETag');
                  resolve({
                    ETag: etag ? etag.replaceAll('"', '') : '',
                    PartNumber: i + 1
                  });
                } else {
                  reject(new Error(`Failed to upload part ${i + 1}`));
                }
              }
            };

            xhr.send(chunk);
          });
        };

        // Function to control the number of parallel uploads
        const uploadPartsInBatches = async () => {
          for (let i = 0; i < urls.length; i += MAX_PARALLEL_UPLOADS) {
            // Check if the upload has been canceled before starting each batch
            if (signal.aborted) {
              console.log('Upload process aborted.');
              throw new Error('Upload aborted');
            }

            const chunkUploads: Promise<UploadPartResult>[] = [];
            for (let j = i; j < i + MAX_PARALLEL_UPLOADS && j < urls.length; j++) {
              const chunk = file.slice(j * PART_SIZE, (j + 1) * PART_SIZE);
              chunkUploads.push(uploadPart(urls[j], chunk, j));
            }

            try {
              // Wait for the current batch of uploads to complete
              const batchResults: UploadPartResult[] = await Promise.all(chunkUploads);
              uploadParts.push(...batchResults);
            } catch (error) {
              // Handle individual part upload failure
              if (signal.aborted) {
                console.log('Batch upload canceled');
                throw new Error('Batch upload aborted');
              } else {
                throw error;  // Re-throw other errors
              }
            }
          }
        };

        try {
          await uploadPartsInBatches();

          const completeData = {
            multipart_upload_id: multipart_upload_id,
            parts: uploadParts,
            ext: 'wav',
            match_id: matchId,
            map_key: mapName,
          };

          fetchFromApi(`/upload_voice_multipart_complete`, {
            method: 'POST',
            body: JSON.stringify(completeData),
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then(response => response.json())
            .then(completeResponse => {
              setUploadStatus("PROCESSING");
              setProgress(0);
              startPollingStatus();
            })
            .catch(error => {
              console.error("Error completing upload: ", error);
              setUploadStatus("ERROR");
              setStatusErrorMessage("Error completing upload.");
            });
        } catch (error) {
          console.error("Error uploading parts: ", error);
          setUploadStatus("ERROR");
          setStatusErrorMessage("Error uploading parts.");
        }
      })
      .catch(error => {
        console.error("Error initiating upload: ", error);
        setUploadStatus("ERROR");
        setStatusErrorMessage("Error initiating upload.");
      });

  };

  useEffect(() => {
    if (loading) {
      setUploadStatus("LOADING");
      setProgress(loadingProgress);
    } else if (loadingProgress === 100) {
      setUploadStatus("SYNC");
      setProgress(100);
    }
  }, [loading, loadingProgress]);

  const startPollingStatus = () => {
    pollingIntervalRef.current = setInterval(() => {
      const queryParams = new URLSearchParams({
        match_id: matchId,
        map_key: mapName,
      }).toString();

      fetchFromApi(`/upload_voice_status?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(statusData => {
          setProgress(statusData.encoding_progress);
          if (statusData.status === 'DONE') {
            onUploadDone();
            clearInterval(pollingIntervalRef.current!);
          } else if (statusData.status === 'NOT_FOUND') {
            setUploadStatus("ERROR");
            setStatusErrorMessage("Upload not found.");
            clearInterval(pollingIntervalRef.current!);
          }
        })
        .catch(error => {
          console.error("Error checking status: ", error);
          setUploadStatus("ERROR");
          setStatusErrorMessage("Error checking status.");
          clearInterval(pollingIntervalRef.current!);
        });
    }, 5000); // Poll every 5 seconds
  };

  useEffect(() => {
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (voiceBuffer && !audio) {
      initializeAudioContext(voiceBuffer, voiceTime);
    }
  }, [voiceBuffer, audio]);

  const handleOffsetSubmit = () => {
    setUploadStatus("SYNC");
    setOffsetSaving(true);

    fetchFromApi(`/update_voice_time`, {
      method: 'POST',
      body: JSON.stringify({ match_id: matchId, map_key: mapName, comms_start_time: currentTime }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        setOffsetSaving(false);
        onSetVoiceTime(currentTime);
        setUploadStatus("DONE")
      })
      .catch(error => {
        console.error("Error updating offset: ", error);
        setOffsetSaving(false);
      });
  };

  const handleDelete = () => {
    setDeleting(true);

    if (audio) {
      audio.pause();
      audio.src = '';
      audio.load();
      setAudio(null);
    }

    fetchFromApi(`/upload_voice_delete`, {
      method: 'POST',
      body: JSON.stringify({ match_id: matchId, map_key: mapName }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        setDeleting(false);
        setOffsetSaving(false);
        setUploadStatus("NEW");
        setProgress(0);
        setFileErrorMessage(null);
        setStatusErrorMessage("");
        onDelete();
      })
      .catch(error => {
        console.error("Error deleting voice comms: ", error);
        setDeleting(false);
      });
  }

  const initializeAudioContext = (voiceBuffer: ArrayBuffer, initialTime: number) => {
    let audioElement: HTMLAudioElement | null = null;
    audioElement = new Audio();
    setAudio(audioElement);
    audioElement.src = URL.createObjectURL(new Blob([voiceBuffer]));

    audioElement.addEventListener('loadedmetadata', () => {
      audioElement!.currentTime = initialTime;
      const updateCurrentTime = () => {
        setCurrentTime(audioElement!.currentTime);
        requestAnimationFrame(updateCurrentTime);
      };

      updateCurrentTime();
    });
  }

  useEffect(() => {
    if (!voiceBuffer) {
      return;
    }
    if (audio) {
      if (playing) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  }, [playing]);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleRewind = () => {
    if (audio) {
      audio.currentTime = Math.max(audio.currentTime - 30, 0);
    }
  };

  const handleForward = () => {
    if (audio) {
      audio.currentTime = Math.max(audio.currentTime + 30, 0);
    }
  };

  // Helper function to format time in 00:00:00.000 format
  const formatTime = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    const milliseconds = Math.floor((timeInSeconds % 1) * 1000);

    const pad = (num: number, size: number) => {
      return ('000' + num).slice(-size);
    };

    return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}.${pad(milliseconds, 3)}`;
  };

  useEffect(() => {
    if (showModal && orgName === undefined) {
      fetchFromApi('/account')
        .then((response) => response.json())
        .then((data) => {
          if (data.org_name === null) {
            setOrgName("");
            return;
          } else {
            setOrgName(data.org_name);
          }
        })
        .catch((error) => {
          setOrgName("");
          console.error("Error:", error);
        });
    }
  }, [showModal]);


  if (showModal && (orgName === "" || !premium)) {
    return <UpgradeInfoModal show={showModal} handleCloseModal={handleCloseModal} />;
  }

  const content = showModal && orgName !== undefined && orgName.length > 0 ?
    <Row>
      <Col>
        <Form>
          <Row className={styles.myrow}>
            <Col>
              <UploadDropZone onChange={handleFileChange} disabled={uploadStatus !== 'NEW'}>
                <p>
                  Drag 'n' drop <code>.wav</code> of the voice comms for {mapName.charAt(0).toUpperCase() + mapName.slice(1)}
                </p>
              </UploadDropZone>
              {fileErrorMessage && <div className={styles.error}>{fileErrorMessage}</div>}
            </Col>
          </Row>
          <Row className={styles.myrow}>
            <Col>
              <ToggleButtonGroup type="radio" name="status" value={uploadStatus} className={styles.statusGroup}>
                <ToggleButton variant="outline-primary" value="INITIALIZING" disabled className={`${styles.statusItem} ${styles.initializing} ${uploadStatus === "INITIALIZING" ? styles.active : styles.inactive}`}>
                  {uploadStatus === "INITIALIZING" && <Spinner animation="grow" size="sm" className={styles.spinnerInitializing} />}<span style={{ marginLeft: 6 }}>Initializing</span>
                </ToggleButton>
                <ToggleButton variant="outline-primary" value="UPLOADING" disabled className={`${styles.statusItem} ${styles.uploading} ${uploadStatus === "UPLOADING" ? styles.active : styles.inactive}`}>
                  {uploadStatus === "UPLOADING" && <Spinner animation="grow" size="sm" className={styles.spinnerUploading} />}<span style={{ marginLeft: 6 }}>Uploading</span>
                </ToggleButton>
                <ToggleButton variant="outline-primary" value="PROCESSING" disabled className={`${styles.statusItem} ${styles.queued} ${uploadStatus === "PROCESSING" ? styles.active : styles.inactive}`}>
                  {uploadStatus === "PROCESSING" && <Spinner animation="grow" size="sm" className={styles.spinnerProcessing} />}<span style={{ marginLeft: 6 }}>Processing</span>
                </ToggleButton>
                <ToggleButton variant="outline-primary" value="LOADING" disabled className={`${styles.statusItem} ${styles.loading} ${uploadStatus === "LOADING" ? styles.active : styles.inactive}`}>
                  {uploadStatus === "LOADING" && <Spinner animation="grow" size="sm" className={styles.spinnerLoading} />}<span style={{ marginLeft: 6 }}>Loading</span>
                </ToggleButton>
                <ToggleButton variant="outline-primary" value="SYNC" disabled className={`${styles.statusItem} ${styles.sync} ${uploadStatus === "SYNC" ? styles.active : styles.inactive}`}>
                  <span>Sync</span>
                </ToggleButton>
                <ToggleButton variant="outline-primary" value="DONE" disabled className={`${styles.statusItem} ${styles.done} ${uploadStatus === "DONE" ? styles.active : styles.inactive}`}>
                  {uploadStatus === "DONE" && <i className="bi bi-check" style={{ color: '#0f0' }}></i>}Ready
                </ToggleButton>
              </ToggleButtonGroup>
            </Col>
          </Row>
          {uploadStatus === 'ERROR' && statusErrorMessage.length > 0 &&
            <Row className={styles.myrow}>
              <Col>
                <div className={styles.error}>
                  {statusErrorMessage &&
                    statusErrorMessage.split('\n').map((item, key) => {
                      return <p key={key} className={styles.error}>{item}</p>
                    })}
                </div>
              </Col>
            </Row>
          }
          {uploadStatus !== "ERROR" && uploadStatus !== "DONE" && uploadStatus !== "SYNC" &&
            <Row className={styles.myrow}>
              <Col>
                <ProgressBar
                  now={progress}
                  label={
                    <>
                      {uploadStatus !== "ERROR" && uploadStatus !== "DONE" &&
                        <div style={{ whiteSpace: 'nowrap' }}>
                          <span>{Math.round(progress)}%</span>
                        </div>
                      }
                    </>
                  }
                  className={stateToProgressBarStyle(uploadStatus)}
                  animated={uploadStatus === "UPLOADING" || uploadStatus === "PROCESSING" || uploadStatus === "LOADING"}
                />
              </Col>
            </Row>
          }
          {(uploadStatus === "SYNC" || uploadStatus === "DONE") && waveform && <>
            <Row className={`${styles.myrow} justify-content-start`}>
              <Col className="flex-grow-1">
                <div>
                  <VoiceCommWaveform
                    waveform={waveform}
                    length={voiceLength}
                    currentTime={audio?.currentTime || 0} // Pass currentTime to waveform
                    voiceStart={voiceTime}
                    scale={50}
                    onClickWaveform={(time) => {
                      if (audio) {
                        audio.currentTime = time;
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className={`${styles.myrow} justify-content-start`}>
              <Col className="col-auto">
                {/* Playback controls */}
                <Button variant="secondary" onClick={handleRewind}>
                  <i className="bi bi-rewind-fill"></i>
                </Button>
                <Button variant="secondary" onClick={handlePlayPause}>
                  {playing ? (
                    <>
                      <i className="bi bi-pause-fill"></i>
                    </>
                  ) : (
                    <>
                      <i className="bi bi-play-fill"></i>
                    </>
                  )}
                </Button>
                <Button variant="secondary" onClick={handleForward}>
                  <i className="bi bi-fast-forward-fill"></i>
                </Button>
              </Col>
              <Col>
                <span className={styles.currentTime}>{formatTime(currentTime)}</span>
              </Col>
              <Col className="col-auto">
                <Button
                  onClick={handleOffsetSubmit}
                  disabled={offsetSaving}
                  style={{ backgroundColor: '#1d5557', border: '1px solid #1d5557' }}
                >
                  {offsetSaving ? <Spinner as="span" animation="border" size="sm" /> : 'Set Pistol Start'}
                </Button>
              </Col>
              <Col className="col-auto">
                <Button
                  variant="danger"
                  onClick={handleDelete}
                  disabled={deleting}
                >
                  {deleting ? <Spinner as="span" animation="border" size="sm" /> : <><i className="bi bi-trash" style={{ marginRight: '7px' }} />Delete</>}
                </Button>
              </Col>
            </Row>
          </>}
        </Form>
      </Col>
    </Row> :
    <Row>
      <Col>
        <div style={{ color: '#aaa' }}>
          <Spinner as="span" animation="border" size="sm" style={{ marginRight: '5px' }} /> Loading...
        </div>
      </Col>
    </Row>;

  return (
    <>
      <Button
        ref={target}
        variant={showModal ? 'secondary' : 'outline-secondary'}
        size="sm"
        className={buttonClasses}
        onClick={() => {
          onClick();
          handleShowModal();
          setShowTooltip(false);
        }}
        onKeyDown={handleKeyDown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <i className={`bi bi-headset ${styles.voiceButton}`}></i>
      </Button>

      <Overlay
        target={target.current}
        show={showTooltip}
        placement="top"
      >
        {(props) => (
          <Tooltip id="tooltip-playback-speed" {...props}>
            Upload Voice Comms
          </Tooltip>
        )}
      </Overlay>

      <Modal show={showModal} onHide={handleCloseModal} dialogClassName={styles.modal}>
        <CustomModalHeader />
        <Modal.Body>
          {content}
        </Modal.Body>
      </Modal>
    </>
  );
}
