import React from "react";
import styles from './TimelineWaveform.module.css';

const TimelineWaveform = ({ waveform, freezetimeEndtimeNormalized }: { waveform: number[], freezetimeEndtimeNormalized: number | undefined }) => {
  const height = 100; // SVG height
  const width = 2000; // SVG width

  // Non-linear transformation function to boost quieter signals
  const applyNonLinearGain = (amplitude: number) => {
    // Adjust this curve to control how much quiet values are boosted
    const factor = 1.9; // Controls the steepness of the curve
    return Math.pow(amplitude, 1 / factor);
  };

  // Helper to generate a path from the waveform
  const generatePath = (waveformPart: number[], startIndex: number) => {
    let path = '';
    const centerY = height / 2;
    const step = width / waveform.length;

    waveformPart.forEach((amplitude, index) => {
      const transformedAmplitude = applyNonLinearGain(amplitude);
      const x = (startIndex + index) * step;
      const y = centerY - transformedAmplitude * centerY;
      path += `${index === 0 ? 'M' : 'L'}${x},${y} `;
    });

    waveformPart.reverse().forEach((amplitude, index) => {
      const transformedAmplitude = applyNonLinearGain(amplitude);
      const x = (startIndex + waveformPart.length - 1 - index) * step;
      const y = centerY + transformedAmplitude * centerY;
      path += `L${x},${y} `;
    });
    waveformPart.reverse(); // Reverse the waveform back to its original order

    path += 'Z'; // Close the path
    return path;
  };

  const generateFreezetimeVerticalLine = () => {
    if (!freezetimeEndtimeNormalized) {
      return null;
    }
    const x = width * freezetimeEndtimeNormalized;
    return <line x1={x} y1={15} x2={x} y2={height-15} stroke="#555" strokeWidth="4" />;
  };

  // Calculate how many points correspond to the freezetime
  const freezetimeIndex = freezetimeEndtimeNormalized ? Math.floor(freezetimeEndtimeNormalized * waveform.length) : 0;
  const firstPart = waveform.slice(0, freezetimeIndex);
  const secondPart = waveform.slice(freezetimeIndex);

  return (
    <svg
      className={styles.waveformContainer}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="none"
    >
      <defs>
        {/* Gradient for the first part (darker and grayer) */}
        <linearGradient id="waveGradientGray" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style={{ stopColor: '#666699', stopOpacity: 0.8 }} />
          <stop offset="100%" style={{ stopColor: '#444477', stopOpacity: 0.8 }} />
        </linearGradient>
        {/* Gradient for the second part (original color) */}
        <linearGradient id="waveGradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style={{ stopColor: '#90528D', stopOpacity: 0.6 }} />
          <stop offset="100%" style={{ stopColor: '#906060', stopOpacity: 0.3 }} />
        </linearGradient>
      </defs>

      {/* First part (freezetime, gray/darker color) */}
      <path
        d={generatePath(firstPart, 0)}
        fill="url(#waveGradientGray)"
      />

      {/* Second part (after freezetime, original color) */}
      <path
        d={generatePath(secondPart, freezetimeIndex)}
        fill="url(#waveGradient)"
      />
    </svg>
  );
};

export default TimelineWaveform;
