import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { fetchFromApi } from "../utils/api";
import styles from "./UpgradeInfo.module.css";
import { Auth } from "aws-amplify";
import SignUpButton from "./SignUpButton";
import useWindowSize from "../useWindowSize";
import { getHasSubscription, getSubscription } from "../utils/premium";
import AlertPopup from "./AlertPopup";
import SourceIcon from "./SourceIcon";


export default function UpgradeInfo({ header, showButton, showTerms, maxWidth }: { header?: JSX.Element, showButton?: boolean, showTerms?: boolean, maxWidth?: number }): JSX.Element {
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [referralCode, setReferralCode] = useState("");
  const { width, height } = useWindowSize();
  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscription, setSubscription] = useState("FREE");
  const [showOrgAlert, setShowOrgAlert] = useState(false);

  useEffect(() => {
    getHasSubscription().then((isPremium) => {
      setHasSubscription(isPremium);
    });
  }, []);

  useEffect(() => {
    getSubscription().then((subscription) => {
      setSubscription(subscription);
    });
  }, []);

  useEffect(() => {
    const getUserEmail = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setEmail(user.attributes.email);
      } catch (error) {
        console.log('error getting user: ', error);
      }
    };

    getUserEmail();
  }, []);

  const handleSubmit = async (event: React.FormEvent, subscription: string) => {
    if (window.gtag) {
      window.gtag('event', 'submit_upgrade_form_click', {
        'value': 1
      });
    }

    const productPart = subscription === 'BASIC' ? 'csgolens-basic' : 'csgolens';
    if (!referralCode) {
      window.open(`https://juhakiili.gumroad.com/l/${productPart}?wanted=true&email=${email}`, '_blank');
    } else {
      if (referralCode === 'FARAO') {
        const affiliateId = '435129043';
        window.open(`https://juhakiili.gumroad.com/l/${productPart}/${referralCode}?wanted=true&email=${email}&affiliate_id=${affiliateId}`, '_blank');
      } else if (referralCode === 'SAMPPA') {
        const affiliateId = '132238035';
        window.open(`https://juhakiili.gumroad.com/l/${productPart}/${referralCode}?wanted=true&email=${email}&affiliate_id=${affiliateId}`, '_blank');
      } else if (referralCode === 'ROUNDSGG') {
        const affiliateId = '587996627';
        window.open(`https://juhakiili.gumroad.com/l/${productPart}/${referralCode}?wanted=true&email=${email}&affiliate_id=${affiliateId}`, '_blank');
      } else if (referralCode === 'DAMIR') {
        const affiliateId = '974223315';
        window.open(`https://juhakiili.gumroad.com/l/${productPart}/${referralCode}?wanted=true&email=${email}&affiliate_id=${affiliateId}`, '_blank');
      } else if (referralCode === 'CX1') {
        const affiliateId = '430003155';
        window.open(`https://juhakiili.gumroad.com/l/${productPart}/${referralCode}?wanted=true&email=${email}&affiliate_id=${affiliateId}`, '_blank');
      } else if (referralCode === 'DIDICO') {
        const affiliateId = '272243731';
        window.open(`https://juhakiili.gumroad.com/l/${productPart}/${referralCode}?wanted=true&email=${email}&affiliate_id=${affiliateId}`, '_blank');
      } else if (referralCode === 'VIPERIO') {
        const affiliateId = '272141331';
        window.open(`https://juhakiili.gumroad.com/l/${productPart}/${referralCode}?wanted=true&email=${email}&affiliate_id=${affiliateId}`, '_blank');
      } else if (referralCode === 'ELITEKLAN') {
        const affiliateId = '792610323';
        window.open(`https://juhakiili.gumroad.com/l/${productPart}/${referralCode}?wanted=true&email=${email}&affiliate_id=${affiliateId}`, '_blank');
      } else {
        window.open(`https://juhakiili.gumroad.com/l/${productPart}/${referralCode}?wanted=true&email=${email}`, '_blank');
      }
    }
    window.location.href = '/checkout/success';
  };

  const handleOrgSubmit = async (event: React.FormEvent) => {
    setShowOrgAlert(true);
  };

  const minimal = width < 800;

  return (
    <><Container style={{ maxWidth: maxWidth ? maxWidth : '800px' }}>
      <Row>
        <Col>
          {header}
        </Col>
      </Row>
      <Row>
        <Col>
          <Table className={styles.priceTable}>
            <thead>
              <tr>
                <th></th>
                {!minimal && <th></th>}
                <th className={`${styles.centeredCell} ${styles.headerLabel}`}>FREE</th>
                <th className={`${styles.centeredCell} ${styles.headerLabelPremium}`}>PREMIUM</th>
                <th className={`${styles.centeredCell} ${styles.headerLabelOrg}`}>ORG</th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles.tableRowLight}>
                <td><i className={`${styles.centeredCell} bi bi-play-btn-fill`} style={{ color: '#777', marginRight: '10px' }} />{minimal ? <>Player</> : <>Player</>}</td>
                {!minimal && <td></td>}
                <td className={styles.centeredCell}><i className={`bi bi-check-lg ${styles.textShadow} ${styles.availableFree}`} /></td>
                <td className={styles.centeredCell}><i className={`bi bi-check-lg ${styles.textShadow} ${styles.available}`} /></td>
                <td className={styles.centeredCell}><i className={`bi bi-check-lg ${styles.textShadow} ${styles.availableOrg}`} /></td>
              </tr>
              <tr className={styles.tableRowDark}>
                <td><i className={`${styles.centeredCell} bi bi-play-btn-fill`} style={{ color: '#777', marginRight: '10px' }} />{minimal ? <>Analyser</> : <>Analyser</>}</td>
                {!minimal &&
                  <td className={styles.preview}>
                    <a href="https://youtu.be/vcml9ww8fS0" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                      <i className="bi bi-play-btn"></i> Preview
                    </a>
                  </td>}
                <td className={styles.centeredCell}><i className={`bi bi-x-lg ${styles.textShadow} ${styles.unavailable}`} /></td>
                <td className={styles.centeredCell}><i className={`bi bi-check-lg ${styles.textShadow} ${styles.available}`} /></td>
                <td className={styles.centeredCell}><i className={`bi bi-check-lg ${styles.textShadow} ${styles.availableOrg}`} /></td>
              </tr>
              <tr className={styles.tableRowLight}>
                <td><i className="bi bi-image-fill" style={{ color: '#777', marginRight: '10px' }} />GIF Exporter</td>
                {!minimal &&
                  <td className={styles.preview}>
                    <a href="https://youtu.be/cvcjZJ8WYnE" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                      <i className="bi bi-play-btn"></i> Preview
                    </a>
                  </td>}
                <td className={styles.centeredCell}><i className={`bi bi-x-lg ${styles.textShadow} ${styles.unavailable}`} /></td>
                <td className={styles.centeredCell}><i className={`bi bi-check-lg ${styles.textShadow} ${styles.available}`} /></td>
                <td className={styles.centeredCell}><i className={`bi bi-check-lg ${styles.textShadow} ${styles.availableOrg}`} /></td>
              </tr>
              <tr className={styles.tableRowDark}>
                <td><i className="bi bi-brush" style={{ color: '#777', marginRight: '10px' }} />Painting</td>
                {!minimal && <td className={styles.preview}>
                  <a href="https://youtu.be/vgUIJoay41g" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <i className="bi bi-play-btn"></i> Preview
                  </a>
                </td>}
                <td className={styles.centeredCell}><i className={`bi bi-x-lg ${styles.textShadow} ${styles.unavailable}`} /></td>
                <td className={styles.centeredCell}><i className={`bi bi-check-lg ${styles.textShadow} ${styles.available}`} /></td>
                <td className={styles.centeredCell}><i className={`bi bi-check-lg ${styles.textShadow} ${styles.availableOrg}`} /></td>
              </tr>
              <tr className={styles.tableRowLight}>
                <td><i className="bi bi-cloud-arrow-up-fill" style={{ color: '#777', marginRight: '10px' }} />
                Upload <span style={{color: '#888', marginLeft: '4px', fontSize: '0.8em', position: 'relative', top: '-1px'}}>GOTV <span style={{color: '#666'}}>|</span> POV</span>
                </td>
                {!minimal && <td className={styles.preview}>
                  <a href="https://youtu.be/wX7h6J7yNwY" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <i className="bi bi-play-btn"></i> Preview
                  </a>
                </td>}
                <td className={styles.centeredCell}><i className={`bi bi-x-lg ${styles.textShadow} ${styles.unavailable}`} /></td>
                <td className={styles.centeredCell}><i className={`bi bi-check-lg ${styles.textShadow} ${styles.available}`} /></td>
                <td className={styles.centeredCell}><i className={`bi bi-check-lg ${styles.textShadow} ${styles.availableOrg}`} /></td>
              </tr>
              <tr className={styles.tableRowDark}>
                <td><i className="bi bi-star-fill" style={{ color: '#777', marginRight: '10px' }} />Playlists</td>
                {!minimal && <td></td>}
                <td className={styles.centeredCell}><i className={`bi bi-x-lg ${styles.textShadow} ${styles.unavailable}`} /></td>
                <td className={styles.centeredCell}><i className={`bi bi-check-lg ${styles.textShadow} ${styles.available}`} /></td>
                <td className={styles.centeredCell}><i className={`bi bi-check-lg ${styles.textShadow} ${styles.availableOrg}`} /></td>
              </tr>
              <tr className={styles.tableRowLight}>
                <td style={{color: '#87b1ff'}}><i className="bi bi-headset" style={{ color: '#87b1ff', marginRight: '10px' }} />Voice Comms</td>
                {!minimal && <td></td>}
                <td className={styles.centeredCell}><i className={`bi bi-x-lg ${styles.textShadow} ${styles.unavailable}`} /></td>
                <td className={styles.centeredCell}><i className={`bi bi-x-lg ${styles.textShadow} ${styles.unavailable}`} /></td>
                <td className={styles.centeredCell}><i className={`bi bi-check-lg ${styles.textShadow} ${styles.availableOrg}`} /></td>
              </tr>
              <tr className={styles.tableRowDark}>
                <td><i className="bi bi-people" style={{ color: '#777', marginRight: '10px' }} />Users</td>
                {!minimal && <td></td>}
                <td className={styles.centeredCell}></td>
                <td className={styles.centeredCell} style={{color: '#ffc983'}}>Single</td>
                <td className={styles.centeredCell} style={{color: '#87b1ff'}}>Unlimited</td>
              </tr>
              <tr className={styles.tableRowMoney}>
                <td><strong style={{ position: 'relative', top: '2px' }}></strong></td>
                <td className={styles.centeredCell}><span style={{ color: `#666`, fontSize: '12px' }}></span></td>
                {!minimal && <td></td>}
                <td className={styles.centeredCell}><div style={{ position: 'relative', top: '2px' }}><strong style={{ fontSize: minimal ? '14px' : '20px' }}>$19</strong><span style={{ color: `#888`, fontSize: '12px', marginLeft: '1px' }}> /mo</span></div></td>
                <td className={styles.centeredCell}><div style={{ position: 'relative', top: '2px' }}><strong style={{ fontSize: minimal ? '14px' : '20px' }}>$49</strong><span style={{ color: `#888`, fontSize: '12px', marginLeft: '1px' }}> /mo</span></div></td>
              </tr>
              <tr className={styles.tableRowMoney}>
                <td><strong style={{ color: `#666`, position: 'relative', top: '2px' }}>With 24% VAT</strong></td>
                {!minimal && <td></td>}
                <td className={styles.centeredCell}><span style={{ color: `#666`, fontSize: '12px' }}></span></td>
                <td className={styles.centeredCell}><div style={{ position: 'relative', top: '2px', left: '-3px' }}><strong style={{ fontSize: minimal ? '14px' : '20px', color: `#666` }}>$23</strong><span style={{ color: `#585858`, fontSize: '14px', marginLeft: '1px' }}>,56</span></div></td>
                <td className={styles.centeredCell}><div style={{ position: 'relative', top: '2px', left: '-3px' }}><strong style={{ fontSize: minimal ? '14px' : '20px', color: `#666` }}>$60</strong><span style={{ color: `#585858`, fontSize: '14px', marginLeft: '1px' }}>,76</span></div></td>
              </tr>
              {(showButton || showButton === undefined) && email &&
                <tr className={styles.tableRowFooter}>
                  <td colSpan={minimal ? 2 : 3} className={styles.footerCell} style={{ paddingLeft: 0 }}>
                    <Form.Group controlId="referralCode" className="w-100">
                      <Form.Control
                        type="text"
                        value={referralCode}
                        onChange={(e) => setReferralCode(e.target.value.toUpperCase())}
                        placeholder="Enter promo code"
                        className="mr-2" // Adds margin to the right of the input field
                      />
                    </Form.Group></td>
                  <td className={styles.footerCell}>
                    <Button
                      variant="success"
                      className={`${styles.upgradeButton} ${styles.upgradeButtonPremium}`}
                      onClick={(e: React.FormEvent) => handleSubmit(e, 'PREMIUM')}
                    >
                      {submitting ? <Spinner animation="border" size="sm" /> : "Buy"}
                    </Button>
                  </td>
                  <td className={styles.footerCell}>
                    <Button
                      variant="success"
                      className={`${styles.upgradeButton} ${styles.upgradeButtonOrg}`}
                      onClick={handleOrgSubmit}
                    >
                      {submitting ? <Spinner animation="border" size="sm" /> : "Buy"}
                    </Button>
                  </td>
                </tr>
              }
            </tbody>
          </Table>
        </Col>
      </Row>
      {(showTerms || showTerms === undefined) &&
        <Row style={{ marginBottom: 15 }}>
          <Col className="d-flex">
            <a
              href="/terms"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#555', fontSize: '12px', textDecoration: 'none' }}>
              Terms and Conditions
            </a>
          </Col>
          <Col className="d-flex justify-content-end">
            <a
              href="/about"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#555', fontSize: '12px', textDecoration: 'none' }}>
              About cs2lens.com
            </a>
          </Col>
        </Row>
      }
      <Row style={{ marginBottom: 20, marginTop: 25 }}>
        <Col className="d-flex justify-content-between align-items-center" style={{ flexWrap: 'nowrap' }}>
          <img src="/promo/xenn2.png" className={styles.xenn} alt="xenn" />
          <div style={{ flex: 1, textAlign: 'center' }}>
            <span className={styles.quote} style={{ display: 'block', fontSize: minimal ? '0.8em' : '1em', fontStyle: 'italic' }}>Outperforms every other demo tool I've ever tried.</span>
            <span className={styles.signature} style={{ display: 'block', fontSize: minimal ? '0.6em' : '0.8em', color: '#888' }}>Sebastian 'xenn' Hoch - Head Coach</span>
          </div>
          <img src="/promo/alternate-attax.png" className={styles.attaxLogo} alt="alternate attax" />
        </Col>
      </Row>
      {(showButton || showButton === undefined) && !email && <>
        <Row>
          <Col className="d-flex">
            <SignUpButton onSignUp={() => window.location.reload()} fullWidth={true} />
          </Col>
        </Row>
      </>
      }
    </Container>
      {showOrgAlert &&
        <AlertPopup show={showOrgAlert} onClose={() => setShowOrgAlert(false)} delay={15000} backgroundColor="#382222" borderColor="#c66">
          <div
            style={{ color: '#888', fontSize: minimal ? '12px' : '15px', textDecoration: 'none', paddingTop: '15px', paddingBottom: '15px' }}>
            <i className="bi bi-bag" style={{ color: 'red', marginRight: '10px' }} />For an <span className={styles.headerLabelOrg}><strong>organization</strong></span> license, please contact us at <a href="https://discord.gg/FdxpTYwtrV" target="_blank" rel="noreferrer">Discord</a> or <code>support@csgolens.com</code>
          </div>
        </AlertPopup>
      }
    </>
  )
}
