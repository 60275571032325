import React, { useEffect, useRef, useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { fetchFromApi } from "../utils/api";
import styles from './Upload.module.css';
import { Col, Container, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as zip from "@zip.js/zip.js";
import ProgressBar from 'react-bootstrap/ProgressBar';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Spinner from 'react-bootstrap/Spinner';
import UploadDropZone from "../components/UploadDropZone";
import TeamNameInput from "../components/TeamNameInput";
import EventNameInput from "../components/EventNameInput";
import { useNavigate } from 'react-router-dom'
import { Buffer } from 'buffer';
import { DemoFile, Player } from "demofile";
import { getShortPlayerName } from "../utils/teams";

interface UploadAPIResponse {
  match_id: string;
  url: string;
  date: string;
  time: string;
  teamA_name: string;
  teamB_name: string;
  teamA_key: string;
  teamB_key: string;
  ext: string;
  content_type: string;
}

interface Players {
  [key: string]: Player;
}

function isValidUrl(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
}

const Upload = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [demoSizeMb, setDemoSizeMb] = useState<number>(0);
  const [matchId, setMatchId] = useState<string>("");
  const [date, setDate] = useState<Date>(new Date(Date.now()));
  const [time, setTime] = useState<string>("12:00");
  const [teamA, setTeamA] = useState<string>("");
  const [teamB, setTeamB] = useState<string>("");
  const [eventName, setEventName] = useState<string>("");
  const [statsUrl, setStatsUrl] = useState<string>("");
  const [fileErrorMessage, setFileErrorMessage] = useState<string>("");
  const [formErrorMessage, setFormErrorMessage] = useState<string>("");
  const [statusErrorMessage, setStatusErrorMessage] = useState<string>("");
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("NEW");
  const [visibility, setVisibility] = useState("UNLISTED");
  const [demofile, setDemofile] = useState<DemoFile | null>(null);
  const [arrayBuffer, setArrayBuffer] = useState<ArrayBuffer | null>(null);

  const [teamAPlayers, setTeamAPlayers] = useState<Players>({});
  const [teamBPlayers, setTeamBPlayers] = useState<Players>({});
  const [firstRoundParsed, setFirstRoundParsed] = useState(false);
  const [parsingError, setParsingError] = useState<string | undefined>(undefined);
  const [parsingStartedTime, setParsingStartedTime] = useState<number | undefined>(undefined);
  const [parsingTimeout, setParsingTimeout] = useState(false);

  const teamANameField = useRef<HTMLInputElement>(null);
  const eventNameField = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const handleFileChange = async (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      setTeamAPlayers({});
      setTeamBPlayers({});
      setParsingError(undefined);
      setParsingStartedTime(undefined);
      setParsingTimeout(false);
      setFirstRoundParsed(false);

      const ext = file.name.split('.').pop();
      if (ext === 'zip') {
        try {
          const reader = new zip.ZipReader(new zip.BlobReader(file));
          const entries = await reader.getEntries();
          if (entries.every(entry => entry.filename.split('.').pop() === 'dem')) {
            setFileErrorMessage("");
            const totalSize = entries.reduce((acc, entry) => acc + entry.uncompressedSize, 0) / 1024 / 1024;
            setDemoSizeMb(totalSize);
            const firstEntry = entries[0];
            console.log('Uncompressing', firstEntry.filename);
            const blob = await firstEntry.getData(new zip.BlobWriter());
            const arrayBuffer = await new Response(blob).arrayBuffer();
            console.log('Parsing demo file for players');
            handleDemoFile(arrayBuffer);
          } else {  // if not, show error message
            setFileErrorMessage("Zip file must contain only .dem files");
            setSelectedFile(null);
          }
        } catch (err) {
          setFileErrorMessage("Could not read .zip file");
          setSelectedFile(null);
        }
      } else if (ext === 'dem') {
        const reader = new FileReader();
        reader.onload = function (event) {
          if (event.target && event.target.result instanceof ArrayBuffer) {
            const arrayBuffer = event.target.result;
            handleDemoFile(arrayBuffer);
          }
        }
        reader.readAsArrayBuffer(file);
        setDemoSizeMb(file.size / 1024 / 1024);
        setFileErrorMessage("");
      } else {
        setFileErrorMessage("File must be a .dem or .zip file");
        setSelectedFile(null);
      }
    }
  };

  useEffect(() => {
    if (parsingStartedTime !== undefined) {
      const intervalId = setInterval(() => {
        if (Date.now() - parsingStartedTime > 6 * 1000) {
          setParsingTimeout(true);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [parsingStartedTime]);


  const handleDemoFile = (file: ArrayBuffer) => {
    if (demofile) {
      demofile.cancel();
    }
    setDemofile(new DemoFile());
    setArrayBuffer(file);
  }

  useEffect(() => {
    if (demofile && arrayBuffer) {
      window.Buffer = Buffer;
      const buffer = Buffer.from(arrayBuffer);

      setParsingStartedTime(Date.now());

      demofile.on("start", () => {
        console.log(
          `clientName = ${demofile.header.clientName}`,
          `gameDirectory = ${demofile.header.gameDirectory}`,
          `magic = ${demofile.header.magic}`,
          `mapName = ${demofile.header.mapName}`,
          `networkProtocol = ${demofile.header.networkProtocol}`,
          `playbackFrames = ${demofile.header.playbackFrames}`,
          `playbackTicks = ${demofile.header.playbackTicks}`,
          `playbackTime = ${demofile.header.playbackTime}`,
          `protocol = ${demofile.header.protocol}`,
          `serverName = ${demofile.header.serverName}`,
          `signonLength = ${demofile.header.signonLength}`,
        );
      });

      demofile.gameEvents.on("round_end", e => {
        if (!firstRoundParsed) {
          const localTeamAPlayers: Players = {};
          const localTeamBPlayers: Players = {};
        
          setTeamA(demofile.teams[2].clanName);
          setTeamB(demofile.teams[3].clanName);
      
          for (let i = 0; i < demofile.players.length; i++) {
            const player = demofile.players[i];
            if (player.teamNumber === 2) {
              localTeamAPlayers[player.steam64Id] = player;
            } else if (player.teamNumber === 3) {
              localTeamBPlayers[player.steam64Id] = player;
            }
          }
                    
          if(Object.keys(localTeamAPlayers).length > 0 && Object.keys(localTeamBPlayers).length > 0) {
            setTeamAPlayers(localTeamAPlayers);
            setTeamBPlayers(localTeamBPlayers);
        
            setFirstRoundParsed(true);
            demofile.cancel();
          }
        }
      });
     

      demofile.on("end", e => {
        if (e.error) {
          setParsingError("Unable to parse the demo file. This demo may not be suitable for upload.");
        }
      });

      demofile.parse(buffer);
    }
  }, [demofile, arrayBuffer, setTeamA, setTeamB]);

  function truncatePlayerList(players: Players) {
    let playerNames = Object.keys(players).map((key) => players[key].name);

    for (let i = 0; i < playerNames.length; i++) {
      playerNames[i] = getShortPlayerName(playerNames[i], Object.keys(players).map((key) => players[key].name));
    }

    let playerNamesString = playerNames.join(",")
    let maxLength = containsUnicode(playerNamesString) ? 18 : 29;
    if (playerNamesString.length > maxLength) {
      playerNamesString = playerNamesString.slice(0, maxLength) + "...";
    }
    return playerNamesString;
  }

  function containsUnicode(str: string) {
    for (let i = 0; i < str.length; i++) {
      if (str.charCodeAt(i) > 127) return true;
    }
    return false;
  }

  const onDateChanged = (date: Date) => {
    setDate(date);
  }

  const onTimeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value);
  }

  // validate form
  const validateForm = () => {
    return selectedFile !== null && teamA.length > 0 && teamB.length > 0 && fileErrorMessage.length === 0 && (visibility === 'PUBLIC' ? eventName.length > 0 : true) && (statsUrl.length === 0 || isValidUrl(statsUrl));
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) {
      if (selectedFile === null) {
        setFormErrorMessage("Missing file!");
      }
      if (fileErrorMessage.length !== 0) {
        setFormErrorMessage("Invalid file!");
      }
      if (teamB.length === 0) {
        setFormErrorMessage("Missing team B name!");
      }
      if (teamA.length === 0) {
        setFormErrorMessage("Missing team A name!");
      }
      if (visibility === 'PUBLIC' && eventName.length === 0) {
        setFormErrorMessage("Missing event name!");
      }
      if (visibility === 'PUBLIC' && statsUrl.length > 0 && !isValidUrl(statsUrl)) {
        setFormErrorMessage(`${statsUrl} is not a valid URL!`);
      }
      return;
    }
    setFormErrorMessage("");

    setUploadStatus("INITIALIZING");
    const formattedDate = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0');
    const fileExtension = selectedFile?.name.split('.').pop();
    const data = {
      date: formattedDate,
      time: time,
      teamA_name: teamA.length <= 32 ? teamA : teamA.substring(0, 32),
      teamB_name: teamB.length <= 32 ? teamB : teamB.substring(0, 32),
      ext: fileExtension,
      visibility: visibility,
      event_name: eventName.length <= 64 ? eventName : eventName.substring(0, 64),
      stats_url: statsUrl,
      teamA_players: Object.keys(teamAPlayers).map((key) => teamAPlayers[key].steam64Id),
      teamB_players: Object.keys(teamBPlayers).map((key) => teamBPlayers[key].steam64Id),
    };

    fetchFromApi(`/upload`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => response.json())
      .then((data: UploadAPIResponse) => {
        if (!selectedFile) {
          throw new Error("No file selected for upload");
        }
        setMatchId(data.match_id);
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', data.url);
        xhr.setRequestHeader('Content-Type', data.content_type);

        // Track progress
        xhr.upload.addEventListener('progress', (e) => {
          if (e.lengthComputable) {
            const percentage = Math.round((e.loaded / e.total) * 100);
            if (percentage > 0) {
              setUploadStatus("UPLOADING");
            }
            setProgress(percentage);
          }
          if (e.loaded === e.total) {
            setUploadStatus("QUEUED");
          }
        });

        xhr.send(selectedFile);
      })
      .catch(error => {
        console.log("Error uploading file: ", error);
      });
  };

  useEffect(() => {
    if (visibility === 'PUBLIC') {
      eventNameField.current?.focus();
    }
  }, [visibility]);

  useEffect(() => {
    if (selectedFile) {
      teamANameField.current?.focus();
    }
  }, [selectedFile]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (uploadStatus === 'QUEUED' || uploadStatus === 'PARSING' || uploadStatus === 'PROCESSING') {
      intervalId = setInterval(() => {
        fetchFromApi(`/upload_status?match_id=${matchId}`)
          .then(res => res.json())
          .then(data => {
            if (data.status !== 'NEW') {
              if (uploadStatus !== 'PARSING' && data.status === 'PARSING') {
                setProgress(0);
              }
              if (data.status === 'PROCESSING') {
                setProgress(data.progress * 100);
              }
              if (data.status === 'DONE') {
                setProgress(100);
              }
              setUploadStatus(data.status);
            }
            // handle the error status from the API
            if (data.error) {
              console.error(data.error);
              setStatusErrorMessage(data.error);
              clearInterval(intervalId);
            }
          })
          .catch(error => console.error('Error fetching upload status: ', error));
      }, 3000);  // repeat every 3 seconds
    }

    // cleanup function
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
  }, [uploadStatus]);  // re-run when `uploadStatus` changes


  const progressTickTime = 1000; // milliseconds between progress increments
  const minProgressIncrement = 100 / ((0.24 * demoSizeMb * 1000) / progressTickTime); // minimum percentage to increment each tick
  const parsingCompleteThreshold = 99; // parsing considered complete at this progress
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    // fake parsing progress
    if (uploadStatus === "PARSING" && progress < parsingCompleteThreshold) {
      intervalId = setInterval(() => {
        // Create a random increment
        const randomIncrement = minProgressIncrement + (Math.random() * minProgressIncrement);
        setProgress(oldProgress => Math.min(oldProgress + randomIncrement, parsingCompleteThreshold));
      }, progressTickTime);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
  }, [uploadStatus, progress, minProgressIncrement]);

  const matchUrl = matchId ? `/match/${matchId}` : null;
  const parsingHasTimedOut = parsingTimeout && Object.keys(teamAPlayers).length === 0 && Object.keys(teamBPlayers).length === 0;

  return (
    <div className={`container ${styles.uploadContainer}`}>
      <Container fluid>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit}>
              <Row className={styles.myrow}>
                <Col>
                  <UploadDropZone onChange={handleFileChange}><></></UploadDropZone>
                  {fileErrorMessage && <div className={styles.error}>{fileErrorMessage}</div>}
                </Col>
              </Row>
              {selectedFile && !firstRoundParsed && !parsingError && !parsingHasTimedOut && <>
                <hr />
                <Row className={styles.myrow}>
                  <Col className={`${styles.parsingPlayers} d-flex justify-content-center align-items-center w-100`}>
                    <Spinner animation="border" role="status" />
                    <span className="sr-only" style={{ paddingLeft: '15px' }}>Parsing players...</span>
                  </Col>
                </Row>
              </>}
              {selectedFile && (Object.keys(teamAPlayers).length < 1 || Object.keys(teamBPlayers).length < 1) && firstRoundParsed && <>
                <hr />
                <Row className={styles.myrow}>
                  <Col>
                    <div className={styles.parsingError}>
                      Couldn't parse players from both teams. This demo may not be suitable for upload.
                    </div>
                  </Col>
                </Row>
              </>}
              {parsingError && parsingError.length > 0 && <>
                <hr />
                <Row className={styles.myrow}>
                  <Col>
                    <div className={styles.parsingError}>
                      {parsingError}
                    </div>
                  </Col>
                </Row>
              </>}
              {parsingHasTimedOut && <>
                <hr />
                <Row className={styles.myrow}>
                  <Col>
                    <div className={styles.parsingError}>
                      Uknown error in parsing player names. This demo may not be suitable for upload.
                    </div>
                  </Col>
                </Row>
              </>}
              {selectedFile && (Object.keys(teamAPlayers).length < 5 || Object.keys(teamBPlayers).length < 5) && firstRoundParsed && <>
                <hr />
                <Row className={styles.myrow}>
                  <Col>
                    <div className={styles.parsingWarning}>
                      WARNING: Only {Object.keys(teamAPlayers).length} vs {Object.keys(teamBPlayers).length} players found. This demo may not be suitable for upload.
                    </div>
                  </Col>
                </Row>
              </>}
              {selectedFile && Object.keys(teamAPlayers).length > 0 && Object.keys(teamBPlayers).length > 0 && firstRoundParsed && (<>
                <hr />
                <Row className={`${styles.myrow} d-flex`}>
                  <Col style={{ flex: '0 0 auto', width: '130px' }}>
                    <Form.Group>
                      <Form.Label>Date</Form.Label>
                      <div>
                        <DatePicker
                          className={`match-datepicker dropdown-toggle btn btn-secondary btn-sm ${styles.dateButton}`}
                          dateFormat="yyyy-MM-dd"
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          selected={date}
                          onChange={onDateChanged} />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col style={{ flex: '0 0 auto', width: '90px' }}>
                    <Form.Group>
                      <Form.Label>Time</Form.Label>
                      <Form.Control type="text" value={time} onChange={onTimeChanged} className={styles.textInput} placeholder="hh:mm" />
                    </Form.Group>
                  </Col>
                  <Col style={{ flexGrow: 1 }}>
                    <Form.Group>
                      <Form.Label>
                        Team A
                        <span style={{ color: '#666', marginLeft: '10px' }}>
                          ({truncatePlayerList(teamAPlayers)})
                        </span>

                      </Form.Label>
                      <TeamNameInput className={styles.textInput} onChange={(value) => setTeamA(value)} value={teamA} />
                    </Form.Group>
                  </Col>
                  <Col style={{ flexGrow: 1 }}>
                    <Form.Group>
                      <Form.Label>
                        Team B
                        <span style={{ color: '#666', marginLeft: '10px' }}>
                          ({truncatePlayerList(teamBPlayers)})
                        </span>

                      </Form.Label>
                      <TeamNameInput className={styles.textInput} onChange={(value) => setTeamB(value)} value={teamB} />
                    </Form.Group>
                  </Col>
                </Row>
                {teamA.length > 0 && teamB.length > 0 && (
                  <Row className={styles.myrow}>
                    <Col>
                      <ToggleButtonGroup className={styles.visibilityGroup} type="radio" name="visibility" value={visibility}>
                        <ToggleButton onClick={() => setVisibility('PUBLIC')} variant="outline-primary" value="PUBLIC" className={`${visibility === 'PUBLIC' ? styles.selected : ''} ${styles.visibilityItem} ${styles.public}`}>
                          <i className="bi bi-eye"></i> <span>Public</span><br /><span className={styles.visibilityExplanation}>For everyone</span>
                        </ToggleButton>
                        <ToggleButton onClick={() => setVisibility('UNLISTED')} variant="outline-primary" value="UNLISTED" className={`${visibility === 'UNLISTED' ? styles.selected : ''} ${styles.visibilityItem} ${styles.unlisted}`}>
                          <i className="bi bi-link-45deg"></i> <span>Unlisted</span><br /><span className={styles.visibilityExplanation}>Anyone with a link</span>
                        </ToggleButton>
                        <ToggleButton onClick={() => setVisibility('PRIVATE')} variant="outline-primary" value="PRIVATE" className={`${visibility === 'PRIVATE' ? styles.selected : ''} ${styles.visibilityItem} ${styles.private}`}>
                          <i className="bi bi-lock-fill"></i> <span>Private</span><br /><span className={styles.visibilityExplanation}>This account only</span>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Col>
                  </Row>
                )}
                {visibility === 'PUBLIC' && <>
                  <Row className={`${styles.myrow} d-flex`}>
                    <Col style={{ flexGrow: 1 }}>
                      <Form.Group>
                        <Form.Label>Event name</Form.Label>
                        <EventNameInput onChange={(value) => setEventName(value)} className={styles.textInput} userEventsOnly={true} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className={`${styles.myrow} d-flex`}>
                    <Col style={{ flexGrow: 1 }}>
                      <Form.Group>
                        <Form.Label>External match URL<span style={{ color: "#666", marginLeft: '8px' }}>(optional)</span></Form.Label>
                        <Form.Control type="text" value={statsUrl} onChange={(e) => setStatsUrl(e.target.value)} className={styles.textInput} placeholder="https://mytournament.com/match/12345" />
                      </Form.Group>
                    </Col>
                  </Row>
                </>}
              </>)}
              {validateForm() && (<>
                <hr />
                {uploadStatus === 'NEW' &&
                  <Row className={styles.myrow}>
                    <Col>
                      {formErrorMessage && <div className={styles.error}>{formErrorMessage}</div>}
                      <Button variant="primary" type="submit" disabled={uploadStatus !== 'NEW'} className={styles.uploadButton}>
                        <><i className="bi bi-box-arrow-up" style={{ paddingRight: 3 }}></i> Upload</>
                      </Button>
                    </Col>
                  </Row>
                }
                {uploadStatus !== 'NEW' && <>
                  <Row className={styles.myrow}>
                    <Col>
                      <ToggleButtonGroup type="radio" name="status" value={uploadStatus} className={styles.statusGroup}>
                        <ToggleButton variant="outline-primary" value="INITIALIZING" disabled className={`${styles.statusItem} ${styles.initializing} ${uploadStatus === "INITIALIZING" ? styles.active : styles.inactive}`}>
                          {uploadStatus === "INITIALIZING" && <Spinner animation="grow" size="sm" className={styles.spinnerInitializing} />}<span style={{ marginLeft: 6 }}>Initializing</span>
                        </ToggleButton>
                        <ToggleButton variant="outline-primary" value="UPLOADING" disabled className={`${styles.statusItem} ${styles.uploading} ${uploadStatus === "UPLOADING" ? styles.active : styles.inactive}`}>
                          {uploadStatus === "UPLOADING" && <Spinner animation="grow" size="sm" className={styles.spinnerUploading} />}<span style={{ marginLeft: 6 }}>File Transfer</span>
                        </ToggleButton>
                        <ToggleButton variant="outline-primary" value="QUEUED" disabled className={`${styles.statusItem} ${styles.queued} ${uploadStatus === "QUEUED" ? styles.active : styles.inactive}`}>
                          {uploadStatus === "QUEUED" && <Spinner animation="grow" size="sm" className={styles.spinnerQueued} />}<span style={{ marginLeft: 6 }}>Queued</span>
                        </ToggleButton>
                        <ToggleButton variant="outline-primary" value="PARSING" disabled className={`${styles.statusItem} ${styles.parsing} ${uploadStatus === "PARSING" ? styles.active : styles.inactive}`}>
                          {uploadStatus === "PARSING" && <Spinner animation="grow" size="sm" className={styles.spinnerParsing} />}<span style={{ marginLeft: 6 }}>Parsing</span>
                        </ToggleButton>
                        <ToggleButton variant="outline-primary" value="PROCESSING" disabled className={`${styles.statusItem} ${styles.processing} ${uploadStatus === "PROCESSING" ? styles.active : styles.inactive}`}>
                          {uploadStatus === "PROCESSING" && <Spinner animation="grow" size="sm" className={styles.spinnerProcessing} />}<span style={{ marginLeft: 6 }}>Processing</span>
                        </ToggleButton>
                        <ToggleButton variant="outline-primary" value="DONE" disabled className={`${styles.statusItem} ${styles.done} ${uploadStatus === "DONE" ? styles.active : styles.inactive}`}>{uploadStatus === "DONE" && <i className="bi bi-check" style={{ color: '#0f0' }}></i>}Done</ToggleButton>
                      </ToggleButtonGroup>
                    </Col>
                  </Row>
                  {uploadStatus === 'ERROR' && statusErrorMessage.length > 0 &&
                    <Row className={styles.myrow}>
                      <Col>
                        <div className={styles.error}>
                          {statusErrorMessage &&
                            statusErrorMessage.split('\n').map((item, key) => {
                              return <p key={key} className={styles.error}>{item}</p>
                            })}
                        </div>
                      </Col>
                    </Row>
                  }
                  <Row className={styles.myrow}>
                    <Col>
                      <ProgressBar
                        now={progress}
                        label={
                          <>
                            <div style={{ whiteSpace: 'nowrap' }}>
                              <span>{Math.round(progress)}%</span>
                            </div>
                          </>
                        }
                        className={uploadStatus === "UPLOADING" ? "uploadProgressBar" : (uploadStatus === "PARSING" ? "parsingProgressBar" : "processingProgressBar")}
                        animated={uploadStatus === "UPLOADING" || uploadStatus === "PARSING" || uploadStatus === "PROCESSING"}
                      />
                    </Col>
                  </Row>
                </>}
              </>)}
              {uploadStatus === 'DONE' && matchUrl && <>
                <hr />
                <Row className={styles.myrow}>
                  <Col>
                    <Button className="btn-sm" style={{ width: '100%' }} onClick={() => {
                      sessionStorage.removeItem('playlist');
                      navigate(matchUrl);
                    }}>
                      <i className="bi bi-play-fill"></i>Replay
                    </Button>
                  </Col>
                </Row>
              </>}
            </Form>
          </Col>
        </Row>
        {uploadStatus !== 'New' && <>
          <hr />
          <Row>
            <Col className="d-flex justify-content-center" style={{ marginTop: 10 }}>
              <div className="feedback-text-wrapper" style={{ display: 'flex' }}>
                <span className="feedback-text" style={{ color: '#888' }}>
                  Feedback?
                </span>
                <span className="feedback-text-arrow"><i className="bi bi-arrow-right"></i></span>
                <div className="discord-button" style={{ marginTop: 1 }}>
                  <a href="https://discord.gg/FdxpTYwtrV" target="_blank" className="discord-button" rel="noreferrer">
                    <img src="/discord-icon.png" height={15}></img>
                    <span className="feedback-label">Discord</span>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </>
        }
      </Container>
    </div>
  );
}

export default Upload;
